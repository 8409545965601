import React, { useEffect, useState } from "react"
import "./styles.css"
import Post from "./post"
import { useTranslation } from "../../utils"
import { getPosts } from "../../api/blog"
import { useAnalytics } from "../../hooks/useTracking"

const BlogSection = () => {
  const { t } = useTranslation()
  const [posts, setPosts] = useState(null)
  const [show, setShow] = useState(true)
  const analytics = useAnalytics()

  useEffect(() => {
    try {
      getPosts().then(res => setPosts(res.data))
    } catch (e) {
      setShow(false)
    }
  }, [])

  if (!show) {
    return <div />
  }
  return (
    <section className="blog-section">
      <div className="container wt-flex wt-flex-middle wt-flex-between wt-margin-bottom-60">
        <h2 className="blog-section__title">{t("Blog")}</h2>
        <a
          onClick={analytics.seeAllPosts}
          href="https://wtotem.com/blog/"
          className="wt-button wt-button--lg"
        >
          {t("See all posts")}
        </a>
      </div>
      <div className="container blog-section__posts">
        {Array.isArray(posts) &&
          posts.map((post, index) => (
            <div className="wt-flex-1" key={index}>
              <Post
                link={post.link}
                title={post.title.rendered}
                imageId={post["featured_media"]}
              />
            </div>
          ))}
      </div>
    </section>
  )
}

export default BlogSection
