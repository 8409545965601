import React, { useState, useEffect } from "react"
import Arrow from "../../images/arrow_green.svg"
import { useTranslation } from "../../utils"
import { getPostMedia } from "../../api/blog"
import { useAnalytics } from "../../hooks/useTracking"

const Post = ({ title, link, imageId }) => {
  const { t } = useTranslation()
  const [image, setImage] = useState()
  const analytics = useAnalytics()

  useEffect(() => {
    getPostMedia(imageId).then(res => setImage(res.data))
  }, [])

  return (
    <div className="blog-section__post">
      <div className="blog-section__post-image">
        {image && <img src={image["source_url"]} alt="image" />}
      </div>
      <div className="blog-section__post-tag">{t("Blog")}</div>
      <div className="blog-section__post-overlay" />
      <a
        onClick={() => analytics.clickOpenPost(title)}
        href={link}
        className="blog-section__post-title"
        rel="noopener"
        target="_blank"
      >
        {title}
      </a>
      <a
        onClick={() => analytics.clickOpenPost(title)}
        href={link}
        className="blog-section__post-link"
        rel="noopener"
        target="_blank"
      >
        {t("Read more")}
        <img src={Arrow} alt="arrow" />
      </a>
    </div>
  )
}

export default Post
