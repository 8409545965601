import React, { useState } from "react"
import "./styles.css"
import Notifications from "../../images/comprehensive-image-notification.png"
import Customize from "../../images/comprehensive-image-customize.png"
import Infected from "../../images/comprehensive-image-infected.png"
import Protection from "../../images/comprehensive-image-protection.png"
import { useAnalytics } from "../../hooks/useTracking"

const tabs = [
  {
    id: 1,
    title: "Notifications",
    description:
      "Get notifications on security and examine the reports on the cyber threats activity",
  },
  {
    id: 2,
    title: "Customize the access",
    description:
      "Customize the access to your websites by adding certain IPs to white/blacklists",
    image: Customize,
  },
  {
    id: 3,
    title: "Infected files",
    description: "Detect infected files and deactivate them",
    image: Infected,
  },
  {
    id: 4,
    title: "Protection",
    description:
      "Protect websites from  hackers, bad bots, SQLi, XSS, spam, Path traversal",
    image: Protection,
  },
]

const ComprehensiveProtection = () => {
  const [currentTab, setCurrentTab] = useState(1)
  const analytics = useAnalytics()

  return (
    <section className="comprehensive-section">
      <div className="container">
        <div className="comprehensive-section__wrapper">
          <div className="comprehensive-section__main">
            <h3 className="comprehensive-section__title">
              <i>Comprehensive</i> protection
            </h3>
            <div className="comprehensive-section__tabs">
              {tabs.map(tab => (
                <div
                  className="comprehensive-section__tab"
                  key={tab.id}
                  data-sal="slide-up"
                  data-sal-delay={`${100 * tab.id}`}
                  data-sal-easing="ease"
                >
                  <button
                    className={`comprehensive-section__tab-button${
                      currentTab === tab.id ? "--active" : ""
                    }`}
                    onClick={() => {
                      setCurrentTab(tab.id)
                      analytics.changeInfo(tab.title)
                    }}
                  >
                    {tab.title}
                  </button>
                  <p>{tab.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div
            className="comprehensive-section__sub"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <div className="comprehensive-section__sub-wrapper">
              <img
                className={`comprehensive-section__sub-image ${
                  currentTab === 1 ? "active" : ""
                }`}
                src={Notifications}
                alt="Notifications"
                key={1}
              />
              <img
                className={`comprehensive-section__sub-image ${
                  currentTab === 2 ? "active" : ""
                }`}
                src={Customize}
                alt="Customize"
                key={2}
              />
              <img
                className={`comprehensive-section__sub-image ${
                  currentTab === 3 ? "active" : ""
                }`}
                src={Infected}
                alt="Infected"
                key={3}
              />
              <img
                className={`comprehensive-section__sub-image ${
                  currentTab === 4 ? "active" : ""
                }`}
                src={Protection}
                alt="Protection"
                key={4}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ComprehensiveProtection
