import React, { useEffect, useRef } from "react"
import "./styles.css"
import TechCrunch from "../../images/tech-crunch.png"
import TechFounders from "../../images/tech-founders.png"
import WiseGuys from "../../images/wise-guys.png"
import Pzu from "../../images/pzu.png"
import Plesk from "../../images/plesk.png"
import { useTranslation } from "../../utils"
import { Link } from "gatsby-plugin-intl"
import Front from "../../images/firewall-ui.webp"
import Back from "../../images/ui.webp"
import { useAnalytics } from "../../hooks/useTracking"
import ScoringSection from "../scoring"

const changeRefTransform = (ref, x, y, z) => {
  if (ref.current) {
    ref.current.style.transform = `rotateY(${y}deg) rotateX(${x}deg) translateZ(${z}px)`
  }
}

const changeRefTransition = (ref, value) => {
  if (ref.current) {
    ref.current.style.transition = value
  }
}

const partners = [
  { logo: TechCrunch, alt: "Tech Crunch" },
  { logo: TechFounders, alt: "Tech Founders" },
  { logo: WiseGuys, alt: "Wise Guys" },
  { logo: Pzu, alt: "Pzu" },
  { logo: Plesk, alt: "Plesk" },
]

const problems = [
  {
    title: "39 seconds",
    text: "There is a hacker attack every 39 seconds",
    classModifier: "seconds",
  },
  {
    title: "Under attack",
    text: "55% of SMBs have experienced a cyberattack in 2020",
    classModifier: "attacks",
  },
  {
    title: "Every day",
    text: "30 000 websites get hacked every single day",
    classModifier: "day",
  },
]

export const HeroSection = () => {
  const { locale } = useTranslation()
  const monitoringBackRef = useRef(null)
  const monitoringFrontRef = useRef(null)
  const firewallBackRef = useRef(null)
  const firewallFrontRef = useRef(null)
  const analytics = useAnalytics()

  useEffect(() => {
    let timer = setTimeout(() => {
      changeRefTransform(firewallFrontRef, 5, 9, 125)
      changeRefTransform(firewallBackRef, 5, 9, 100)
      changeRefTransform(monitoringFrontRef, 5, 9, 25)
      changeRefTransform(monitoringBackRef, 5, 9, 0)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const handleSectionMouseMove = e => {
    let xAxisF = (e.pageX - window.innerWidth / 2) / 40
    let yAxisF = (window.innerHeight / 4 - e.pageY) / 40
    let xAxisM = (e.pageX - window.innerWidth / 2) / 90
    let yAxisM = (window.innerHeight / 4 - e.pageY) / 90
    changeRefTransform(firewallFrontRef, yAxisF, xAxisF, 125)
    changeRefTransform(firewallBackRef, yAxisF, xAxisF, 100)
    changeRefTransform(monitoringFrontRef, yAxisM, xAxisM, 25)
    changeRefTransform(monitoringBackRef, yAxisM, xAxisM, 0)
  }

  const handleSectionMouseEnter = () => {
    changeRefTransition(monitoringFrontRef, "all 1s ease-out")
    changeRefTransition(monitoringBackRef, "all 1s ease-out")
    changeRefTransition(firewallBackRef, "all 1s ease-out")
    changeRefTransition(firewallFrontRef, "all 1s ease-out")
    setTimeout(() => {
      changeRefTransition(monitoringFrontRef, "none")
      changeRefTransition(monitoringBackRef, "none")
      changeRefTransition(firewallBackRef, "none")
      changeRefTransition(firewallFrontRef, "none")
    }, 1000)
  }

  const handleSectionMouseLeave = () => {
    changeRefTransition(monitoringFrontRef, "all 1s ease")
    changeRefTransition(monitoringBackRef, "all 1s ease")
    changeRefTransition(firewallBackRef, "all 1s ease")
    changeRefTransition(firewallFrontRef, "all 1s ease")
    changeRefTransform(firewallFrontRef, 5, 9, 125)
    changeRefTransform(firewallBackRef, 5, 9, 100)
    changeRefTransform(monitoringFrontRef, 5, 9, 25)
    changeRefTransform(monitoringBackRef, 5, 9, 0)
  }

  return (
    <section className="hero-section__wrapper">
      <div className="hero-section" lang={locale}>
        <div className="hero-section__noise" />
        <div className="container">
          <div className="hero-section__main">
            <div className="hero-section__main-text">
              <h1>Secure Your Brand by Protecting Your Website.</h1>
              <p>
                Get a simple and clear solution that takes care of your security
                problems
              </p>
              <Link onClick={analytics.tryNowClicked} to="/pricing">
                <button className="wt-button wt-button--white">
                  <span>Try now</span>
                </button>
              </Link>
            </div>
            <div
              className="hero-section__3d-block"
              onMouseMove={handleSectionMouseMove}
              onMouseEnter={handleSectionMouseEnter}
              onMouseLeave={handleSectionMouseLeave}
            >
              <img
                src={Front}
                alt={""}
                className="hero-section__firewall-front"
                ref={firewallFrontRef}
                loading="eager"
              />
              <div
                className="hero-section__firewall-back"
                ref={firewallBackRef}
              />
              <img
                src={Back}
                alt={""}
                className="hero-section__monitoring-front"
                ref={monitoringFrontRef}
                loading="eager"
              />
              <div
                className="hero-section__monitoring-back"
                ref={monitoringBackRef}
              />
            </div>
          </div>
        </div>
      </div>
      <ScoringSection hero={true} />
      <div className="hero-section__partners">
        {partners.map(partner => (
          <div key={partner.alt} className="hero-section__partners-wrapper">
            <img src={partner.logo} alt={partner.alt} />
          </div>
        ))}
      </div>
      <ul className="hero-section__problems container">
        {problems.map(item => (
          <li
            key={item.text}
            className={`hero-section__problem hero-section__problem--${item.classModifier}`}
          >
            <span className="hero-section__problem-title">{item.title}</span>
            <span className="hero-section__problem-text">{item.text}</span>
          </li>
        ))}
      </ul>
    </section>
  )
}
