import React from "react"
import { openHubspotChat } from "../../utils"
import "./styles.css"
import Bubble1 from "../../images/full-day-section-bubble-1.png"
import Bubble2 from "../../images/full-day-section-bubble-2.png"
import Bubble3 from "../../images/full-day-section-bubble-3.png"
import Bubble4 from "../../images/full-day-section-bubble-4.png"
import { useAnalytics } from "../../hooks/useTracking"

const FullDay = () => {
  const analytics = useAnalytics()
  return (
    <section
      className="full-day-section"
      data-sal="slide-up"
      data-sal-delay="100"
      data-sal-easing="ease"
    >
      <div className="container">
        <div className="full-day-section__wrapper">
          <div className="full-day-section__wrapper-text">
            <h3 className="full-day-section__title">
              Get live-chat support 24/7
            </h3>
            <p>
              Need help with installation? <br />
              Having questions about the plans or features?
            </p>
            <br />
            <p>
              Contact support team 24/7 via chat or email: support@wtotem.com
            </p>
            <button
              className="wt-button wt-button--white"
              onClick={() => {
                analytics.getInTouch()
                openHubspotChat()
              }}
            >
              <span>Get in touch</span>
            </button>
          </div>
          <div className="full-day-section__snake" />
          <div className="full-day-section__snake-2" />
          <img
            src={Bubble1}
            alt=""
            className="full-day-section__bubble-1"
            data-sal="slide-up"
            data-sal-delay="750"
            data-sal-easing="ease"
          />
          <img
            src={Bubble2}
            alt=""
            className="full-day-section__bubble-2"
            data-sal="slide-up"
            data-sal-delay="650"
            data-sal-easing="ease"
          />
          <img
            src={Bubble3}
            alt=""
            className="full-day-section__bubble-3"
            data-sal="slide-up"
            data-sal-delay="550"
            data-sal-easing="ease"
          />
          <img
            src={Bubble4}
            alt=""
            className="full-day-section__bubble-4"
            data-sal="slide-up"
            data-sal-delay="450"
            data-sal-easing="ease"
          />
        </div>
      </div>
    </section>
  )
}

export default FullDay
