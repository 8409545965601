import React, { useState } from "react"
import "./styles.css"
import { Link } from "gatsby-plugin-intl"
import Balls from "../../images/solution-section-balls.png"
import { SolutionSectionTab } from "../../components/solution-section-tab"
import SolutionSectionSliderTabs from "../../components/solution-section-slider-tabs"
import FirewallImg from "../../images/solution-section-firewall-tab.webp"
import AntivirusImg from "../../images/solution-section-antivirus-tab.webp"
import MonitoringImg from "../../images/solution-section-monitoring-tab.webp"
import VMImg from "../../images/solution-section-vm-tab.webp"
import GuardIcon from "../../images/guard.svg"
import MonitoringIcon from "../../images/monitoring.svg"
import HealthBoardIcon from "../../images/healthboard.svg"
import { useAnalytics } from "../../hooks/useTracking"

const content = {
  1: {
    title: "Firewall",
    about:
      "AI-inspired proactive protection against intrusions and new emerging threats",
    tricks: [
      "Protects from all known attacks",
      "Brute force protection",
      "DoS Protection",
      "Custom rules",
      "AI and Herd Immunity",
    ],
    img: FirewallImg,
    description: (
      <>
        <p>
          There is a hacker attack every 39 seconds. But it is no longer your
          concern with WebTotem Firewall.
        </p>
        <p>
          Firewall saves your websites from hackers, bad bots, SQLi, XSS, spam,
          Path traversal, Dos Attack and many other types of known and emerging
          attacks.
        </p>
        <p>Get peace of mind knowing that your website is safe with WebTotem</p>
      </>
    ),
    cards: [
      {
        icon: GuardIcon,
        title: "Attack report",
        description:
          "Find out information about hackers: IP, time of attempts and country of origin and take proactive measures",
      },
      {
        icon: MonitoringIcon,
        title: "AI and Herd Immunity",
        description:
          "Only legitimate users have access to your website. AI Firewall creates individual protection for each website, taking into account all its specifics",
      },
      {
        icon: HealthBoardIcon,
        title: "Brute Force Protection",
        description:
          "Get protection from hackers' repeated attempts to gain access to your account",
      },
      {
        icon: GuardIcon,
        title: "Custom rules",
        description:
          "With a click of a button you can block or whitelist an IP and easily add exception rules to URLs",
      },
      {
        icon: MonitoringIcon,
        title: "External monitoring",
        description:
          "Protect your website from the attackers that intended to hack other websites. Firewall has a malicious IP database and can block them in advance",
      },
      {
        icon: HealthBoardIcon,
        title: "Dos Protection",
        description:
          "Keep your website available and working with protection from denial-of-service attacks\n",
      },
    ],
  },
  2: {
    title: "Antivirus",
    about:
      "Server-side scanner that neutralizes malware in one click and notifies about new, modified and deleted files",
    tricks: [
      "Malware prevention",
      "File control and permission checks",
      "Automatic and on demand scanning",
      "Malware Removal",
    ],
    img: AntivirusImg,
    description: (
      <>
        <p>
          An infected file can lead to a complete shutdown of the website. Don’t
          let viruses destroy your business.
        </p>
        <p>
          Find infected files with WebTotem Antivirus and stop their destructive
          behaviour.
        </p>
      </>
    ),
    cards: [
      {
        icon: GuardIcon,
        title: "Malware Removal",
        description:
          "It helps you take actions when malware is found. Delete or exclude infected files with one click",
      },
      {
        icon: MonitoringIcon,
        title: "File control and permission checks",
        description:
          "Control your file changes and make sure no infected files are uploaded. Antivirus scans new files automatically and detects the changes in rights and permissions",
      },
      {
        icon: HealthBoardIcon,
        title: "Fast and unlimited",
        description: "It scans automatically and whenever you want",
      },
    ],
  },
  3: {
    title: "Monitoring",
    about:
      "Continuous external monitoring of downtime, web reputation, SSL, domain expiry and much more",
    tricks: [
      "Availability check",
      "Deface Scanner",
      "Server Resources monitoring",
      "SSL Expiration monitoring",
      "Blacklisting monitoring",
    ],
    img: MonitoringImg,
    description: (
      <p>Manage your website assets with a rich set of monitoring tools.</p>
    ),
    cards: [
      {
        icon: GuardIcon,
        title: "SSL",
        description:
          "WebTotem won’t let you miss the day of SSL Certificate expiration. Plan certificate prolongation in advance",
      },
      {
        icon: MonitoringIcon,
        title: "Availability",
        description:
          "Make sure that your site is accessible to customers and you haven’t lost your traffic",
      },
      {
        icon: HealthBoardIcon,
        title: "Reputation",
        description:
          "Take care of your presence in search results and take actions if your website is blacklisted",
      },
      {
        icon: GuardIcon,
        title: "Deface scanner",
        description:
          "Make sure that no page of your website has been replaced by a hacker and your website does not mislead your customers",
      },
      {
        icon: MonitoringIcon,
        title: "Server Resources",
        description:
          "Boost your website performance. Control RAM/CPU load data and server disc usage",
      },
    ],
  },
  4: {
    title: "Vulnerability Management",
    about: "Continuous identification and elimination of vulnerabilities",
    tricks: ["Open ports Scanner", "Scoring"],
    img: VMImg,
    description: (
      <>
        <p>
          70% of all security breaches today occur due to vulnerabilities with
          the application and server misconfigurations.
        </p>
        <p>
          WebTotem not only protects websites in real time, but also helps to
          reduce the risks of attacks in advance.
        </p>
      </>
    ),
    cards: [
      {
        icon: GuardIcon,
        title: "Open Ports Scanner",
        description:
          "Detect open ports on the web server that can be exploited by intruders.",
      },
      {
        icon: MonitoringIcon,
        title: "Scoring",
        description: (
          <>
            <span>
              Find out your website security pain points and follow our
              recommendations to reduce the efficiency hacker attempts.
            </span>
          </>
        ),
      },
    ],
  },
}

export const Solution = () => {
  const [activeTab, setActiveTab] = useState(1)
  const [open, setOpen] = useState(false)
  const analytics = useAnalytics()

  return (
    <section className="solution-section">
      <div className="solution-section__noise">
        <img
          src={Balls}
          className="solution-section__balls"
          alt="balls"
          width="100%"
        />
      </div>

      <div className="container">
        <div className="solution-section__header">
          <h3 className="solution-section__header__title">
            All-in-One solution that <i>protects your website</i>
          </h3>
        </div>
        <div className="solution-section__tabs">
          <button
            className={`solution-section__tab firewall ${
              activeTab === 1 ? "active" : ""
            }`}
            onClick={() => {
              analytics.changeTab("Firewall")
              setActiveTab(1)
            }}
          />
          <button
            className={`solution-section__tab antivirus ${
              activeTab === 2 ? "active" : ""
            }`}
            onClick={() => {
              analytics.changeTab("Antivirus")
              setActiveTab(2)
            }}
          />
          <button
            className={`solution-section__tab monitoring ${
              activeTab === 3 ? "active" : ""
            }`}
            onClick={() => {
              analytics.changeTab("Monitoring")
              setActiveTab(3)
            }}
          />
          <button
            className={`solution-section__tab vulnerability ${
              activeTab === 4 ? "active" : ""
            }`}
            onClick={() => {
              analytics.changeTab("Vulnerability Management")
              setActiveTab(4)
            }}
          />
        </div>
        <div
          className="solution-section__content"
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <SolutionSectionTab
            activeTab={activeTab}
            open={open}
            isActive={activeTab === 1}
            setOpen={setOpen}
            data={content[1]}
          />
          <SolutionSectionTab
            activeTab={activeTab}
            open={open}
            setOpen={setOpen}
            isActive={activeTab === 2}
            data={content[2]}
          />
          <SolutionSectionTab
            activeTab={activeTab}
            open={open}
            setOpen={setOpen}
            isActive={activeTab === 3}
            data={content[3]}
          />
          <SolutionSectionTab
            activeTab={activeTab}
            open={open}
            setOpen={setOpen}
            isActive={activeTab === 4}
            data={content[4]}
          />
        </div>
        {!open && (
          <div className="solution-section__footer">
            <Link onClick={analytics.tryForFreeClicked} to="/pricing">
              <button className="wt-button wt-button--white">
                <span>Try now</span>
              </button>
            </Link>
          </div>
        )}
      </div>
      <SolutionSectionSliderTabs activeTab={activeTab} />
    </section>
  )
}
