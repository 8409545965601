import axios from "../axios"

export const getPosts = () => {
  return axios.get(
    "https://wtotem.com/blog/wp-json/wp/v2/posts?per_page=3&_fields[]=title&_fields[]=link&_fields[]=date&_fields[]=yoast_head_json&_fields[]=featured_media&_fields[]=id"
  )
}

export const getPostMedia = id => {
  return axios.get(
    `https://wtotem.com/blog/wp-json/wp/v2/media/${id}?_fields=source_url`
  )
}
