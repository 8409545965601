import React, { useState } from "react"
import "./styles.css"
import Ssl from "../../images/advanced-security-ssl.svg"
import Boost from "../../images/advanced-security-boost.svg"
import Rec from "../../images/advanced-security-recommendation.svg"
import { useAnalytics } from "../../hooks/useTracking"

const tabs = [
  {
    id: 0,
    title: "SSL",
    description:
      "Control your SSL certification expiration date and take actions in time",
    image: Ssl,
  },
  {
    id: 1,
    title: "Boost your website",
    description: "Monitor server resources usage and boost website performance",
    image: Boost,
  },
  {
    id: 2,
    title: "Advanced recommendations",
    description:
      "Detect your website security vulnerabilities and follow advanced recommendations on protection",
    image: Rec,
  },
]
const AdvancedSecurity = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const analytics = useAnalytics()

  return (
    <section className="advanced-security-section">
      <div className="container">
        <div
          className="advanced-security-section__sub"
          data-sal="slide-right"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <div className="advanced-security-section__sub-wrapper">
            <img
              className={`advanced-security-section__sub-image ${
                currentTab === 0 ? "active" : ""
              }`}
              src={Ssl}
              alt="SSL"
            />
            <img
              className={`advanced-security-section__sub-image ${
                currentTab === 1 ? "active" : ""
              }`}
              src={Boost}
              alt="Boost your website"
            />

            <img
              className={`advanced-security-section__sub-image ${
                currentTab === 2 ? "active" : ""
              }`}
              src={Rec}
              alt="Advanced recommendations"
            />
          </div>
        </div>
        <div
          className="advanced-security-section__main"
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <h3 className="advanced-security-section__title">
            <i>Advanced</i> security
          </h3>
          <div className="advanced-security-section__tabs">
            {tabs.map(tab => (
              <button
                className={`advanced-security-section__tab${
                  tab.id === currentTab ? "--active" : ""
                }`}
                key={tab.id}
                onClick={() => {
                  analytics.changeInfo(tab.title)
                  setCurrentTab(tab.id)
                }}
              >
                <span>{tab.title}</span>
                <p>{tab.description}</p>
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AdvancedSecurity
