import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroSection } from "../sections/hero"
import PricingSection from "../sections/pricing"
import ScoringSection from "../sections/scoring"
import BlogSection from "../sections/blog"
import Footer from "../sections/footer"
import Header from "../sections/header"
import { getQueryParams, useTranslation } from "../utils"
import { WtotemAi } from "../sections/wtotem-ai"
import { Solution } from "../sections/solution"
import ComprehensiveProtection from "../sections/comprehensive-protection"
import AdvancedSecurity from "../sections/advanced-security"
import Collaboration from "../sections/collaboration"
import PreventDetectRespond from "../sections/prevent-detect-respond"
import ForPeople from "../sections/for-people"
import FullDay from "../sections/full-day"
import CustomPlanPricing from "../sections/custom-plan-pricing"
import { CONFIG } from "../config"
import { useAnalytics } from "../hooks/useTracking"

if (typeof window !== "undefined") {
  import("amplitude-js").then(amplitude => {
    amplitude.getInstance().init(CONFIG.AMPLITUDE_KEY)
  })
}

const IndexPage = ({ location }) => {
  const { t } = useTranslation()

  useEffect(() => {
    const resetPassCode = getQueryParams(location.search)["reset-pass"]
    if (resetPassCode) {
      localStorage.setItem("reset-pass", resetPassCode)
      window.location.href = "/cabinet/reset"
    }
  }, [location])

  return (
    <Layout>
      <SEO title={t("Website Security Monitoring")} />
      <Header theme={"dark"} />
      <HeroSection />
      <WtotemAi />
      <Solution />
      <ComprehensiveProtection />
      <AdvancedSecurity />
      <Collaboration />
      <PreventDetectRespond />
      <ForPeople />
      <PricingSection />
      <CustomPlanPricing />
      <FullDay />
      <BlogSection />
      <ScoringSection />
      <Footer />
    </Layout>
  )
}

export default IndexPage
