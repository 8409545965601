import React from "react"
import "./styles.css"
import Bg from "../../images/collaboration-section-bg.webp"
import Report from "../../images/collaboration-section-report.png"
import Account from "../../images/collaboration-section-account.png"

const tabs = [
  {
    title: "Monitoring",
    description:
      "Monitor all your websites from one dashboard and find pain points at a glance",
  },
  {
    title: "Reports",
    description: "Send detailed reports on the security issues to your clients",
  },
  {
    title: "Team",
    description: "Invite team members for collaboration",
  },
]

const Collaboration = () => {
  return (
    <section className="collaboration-section">
      <div className="container">
        <div
          className="collaboration-section__main"
          data-sal="slide-right"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <h3 className="collaboration-section__title">Collaboration</h3>
          <div className="collaboration-section__tabs">
            {tabs.map(tab => (
              <div key={tab.title} className="collaboration-section__tab">
                <span>{tab.title}</span>
                <p>{tab.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div
          className="collaboration-section__sub"
          data-sal="slide-left"
          data-sal-delay="100"
          data-sal-easing="ease"
        >
          <div className="collaboration-section__sub-wrapper">
            <img
              src={Report}
              alt="Report"
              className="collaboration-section__sub-report"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-easing="ease"
            />
            <img
              src={Account}
              alt="Account"
              className="collaboration-section__sub-account"
              data-sal="slide-left"
              data-sal-delay="500"
              data-sal-easing="ease"
            />
            <img src={Bg} alt="" className="collaboration-section__sub-bg" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Collaboration
