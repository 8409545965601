import React from "react"
import "./styles.css"
import WtAiImg from "../../images/wt-ai-section-img.jpg"
export const WtotemAi = () => {
  return (
    <section className="wt-ai-section container">
      <div
        className="wt-ai-section__meet"
        data-sal="slide-up"
        data-sal-delay="100"
        data-sal-easing="ease"
      >
        <small>Meet WebTotem AI</small>
        <h4>
          <span className="wt-text wt-text--blue">Get peace of mind</span> with
          <span className="wt-text wt-text--blue"> WebTotem</span>
        </h4>
      </div>
      <div
        className="wt-ai-section__content"
        data-sal="slide-up"
        data-sal-delay="100"
        data-sal-easing="ease"
      >
        <div className="wt-ai-section__content__item">
          <h5>Stop worrying</h5>
          <p>
            Stop worrying about data and money loss and sleep tight, while
            WebTotem is doing all the work for you.
          </p>
        </div>
        <div className="wt-ai-section__content__item">
          <h5>Save time and money</h5>
          <p>
            Save time and money on employing security specialists or trying to
            secure your websites on your own.
          </p>
        </div>
        <div className="wt-ai-section__content__item">
          <h5>Fast setup</h5>
          <p>
            Forget about complicated setup, professional jargon or additional
            coding. Get your websites secured in 5 minutes.
          </p>
        </div>
      </div>
      <img
        src={WtAiImg}
        alt="overview"
        width="100%"
        data-sal="slide-up"
        data-sal-delay="100"
        data-sal-easing="ease"
      />
    </section>
  )
}
