import React from "react"
import "./styles.css"
import Mac from "../../images/prevent-detect-respond-mac.webp"

const PreventDetectRespond = () => {
  return (
    <section
      className="prevent-detect-respond-section"
      data-sal="slide-up"
      data-sal-delay="100"
      data-sal-easing="ease"
    >
      <div className="container">
        <h2 className="prevent-detect-respond-section__title">
          <i>Prevent</i> easily. <i>Detect</i> smartly. <i>Respond</i> fast.
        </h2>
        <span className="prevent-detect-respond-section__description">
          Your personal AI-powered web application security assistant
        </span>
        <img
          className="prevent-detect-respond-section__mac"
          src={Mac}
          alt="Mac"
        />
      </div>
      <div className="prevent-detect-respond-section__bg" />
    </section>
  )
}

export default PreventDetectRespond
