import React, { useState } from "react"
import { Link } from "gatsby-plugin-intl"
import "./styles.css"
import { useAnalytics } from "../../hooks/useTracking"

const tabs = [
  {
    title: "For individuals & SMBs",
    description:
      "Protect your website so you do not get hacked.\n" +
      "\n" +
      "Prevent reputational and money loss.\n" +
      "\n" +
      "Let the best-in-class cybersecurity experts get your back.\n" +
      "\n" +
      "Ensure site availability and positive customer experience.",
  },
  {
    title: "For Agency",
    description:
      "Increase your revenue by providing security services.\n" +
      "\n" +
      "Deliver exceptional experience to your clients.\n" +
      "\n" +
      "Monitor all your clients' websites from a single dashboard.\n" +
      "\n" +
      "Impress your clients with the company branded reports.",
  },
  {
    title: "For Partners",
    description:
      "Monitor company’s web assets in the one dashboard.\n" +
      "\n" +
      "Get detailed reports and incidents logs.\n" +
      "\n" +
      "Employ Cyber Risk Assessment & Management.\n" +
      "\n" +
      "Enable your team to do their jobs better and faster.",
  },
]

const ForPeople = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const analytics = useAnalytics()

  return (
    <section className="for-people-section">
      <div className="container">
        <div className="for-people-section__tabs">
          <button
            className={`for-people-section__tab individuals ${
              currentTab === 0 ? "active" : ""
            }`}
            onClick={() => {
              analytics.changePlans("For individuals & SMBs")
              setCurrentTab(0)
            }}
          />
          <button
            className={`for-people-section__tab agency ${
              currentTab === 1 ? "active" : ""
            }`}
            onClick={() => {
              analytics.changePlans("For Agency")
              setCurrentTab(1)
            }}
          />
          <button
            className={`for-people-section__tab people ${
              currentTab === 2 ? "active" : ""
            }`}
            onClick={() => {
              analytics.changePlans("For Partners")
              setCurrentTab(2)
            }}
          />
        </div>
        <h3 className="for-people-section__title">{tabs[currentTab].title}</h3>
        <p className="for-people-section__description">
          {tabs[currentTab].description}
        </p>
        <Link onClick={analytics.tryNow} to="/pricing">
          <button className="wt-button wt-button--red-blue">Try now</button>
        </Link>
      </div>
    </section>
  )
}

export default ForPeople
