import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./styles.css"
import { useTranslation } from "../../utils"

const Switch = props => {
  const { t } = useTranslation()

  return (
    <div className={classNames("switch", `switch--${props.theme}`)}>
      <div
        onClick={() => props.onChange("monthly")}
        className={classNames("switch__item", {
          "switch__item--active": props.cycle === "monthly",
        })}
      >
        {t("Monthly")}
      </div>
      <div
        onClick={() => props.onChange("yearly")}
        className={classNames("switch__item", {
          "switch__item--active": props.cycle === "yearly",
        })}
      >
        <span className="switch__discount">-20%</span>
        {t("Yearly")}
      </div>
    </div>
  )
}

Switch.propTypes = {
  theme: PropTypes.oneOf(["light", "dark", "gray"]).isRequired,
  cycle: PropTypes.oneOf(["monthly", "yearly"]).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Switch
