import React from "react"
import "./styles.css"
import PropTypes from "prop-types"
import classNames from "classnames"

const PricingCard = ({ showCostInMobile = true, ...props }) => {
  return (
    <div
      className={classNames("pricing-card", {
        "pricing-card--popular": props.mostPopular,
      })}
    >
      <div>
        <div className="wt-flex wt-flex-between wt-flex-middle md-wt-flex-column pricing-card__header">
          <div>
            <p className="pricing-card__tier">{props.tier}</p>
            <p className="pricing-card__description">{props.description}</p>
          </div>
          <div className="pricing-card__cost-container">
            <p className="pricing-card__cost">{props.cost}</p>
          </div>
        </div>
        <div>
          <p className="pricing-card__included">What's included:</p>
          <ul className="pricing-card__list">
            {props?.included?.map(item => (
              <li key={item} className="pricing-card__list-item">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="pricing-card__action">
        <button
          className="pricing-card__button wt-button"
          onClick={props.trigger}
        >
          Sign Up
        </button>
        <p className="pricing-card__trial">{props.trial}</p>
      </div>
    </div>
  )
}

PricingCard.propTypes = {
  badgeYellow: PropTypes.string,
  responsive: PropTypes.bool,
  setActive: PropTypes.func,
  active: PropTypes.bool,
  floating: PropTypes.string,
  included: PropTypes.arrayOf(PropTypes.string),
  badge: PropTypes.string.isRequired,
  yellow: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cost: PropTypes.string,
  quantity: PropTypes.string,
  backQuantity: PropTypes.string,
  amount: PropTypes.string,
  buttonText: PropTypes.string,
  trigger: PropTypes.func,
  trial: PropTypes.string,
  link: PropTypes.string,
  showCostInMobile: PropTypes.bool,
  withLink: PropTypes.bool,
  cycle: PropTypes.oneOf(["monthly", "yearly"]),
  badgeColor: PropTypes.string,
}

export default PricingCard
