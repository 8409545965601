import React from "react"
import "./styles.css"
import GuardIcon from "../../images/guard.svg"
import MonitoringIcon from "../../images/monitoring.svg"
import HealthBoardIcon from "../../images/healthboard.svg"
import { Link } from "gatsby"

const cards = {
  1: {
    cards: [
      {
        icon: GuardIcon,
        title: "Attack report",
        description:
          "Find out information about hackers: IP, time of attempts and country of origin and take proactive measures",
      },
      {
        icon: MonitoringIcon,
        title: "AI and Herd Immunity",
        description:
          "Only legitimate users have access to your website. AI Firewall creates individual protection for each website, taking into account all its specifics",
      },
      {
        icon: HealthBoardIcon,
        title: "Brute Force Protection",
        description:
          "Get protection from hackers' repeated attempts to gain access to your account",
      },
      {
        icon: GuardIcon,
        title: "Custom rules",
        description:
          "With a click of a button you can block or whitelist an IP and easily add exception rules to URLs",
      },
      {
        icon: MonitoringIcon,
        title: "External monitoring",
        description:
          "Protect your website from the attackers that intended to hack other websites. Firewall has a malicious IP database and can block them in advance",
      },
      {
        icon: HealthBoardIcon,
        title: "Dos Protection",
        description:
          "Keep your website available and working with protection from denial-of-service attacks\n",
      },
    ],
  },
  2: {
    cards: [
      {
        icon: GuardIcon,
        title: "Malware Removal",
        description:
          "It helps you to take actions when malware is found. Delete or exclude infected files with one click",
      },
      {
        icon: MonitoringIcon,
        title: "File control 24/7",
        description:
          "Control your file changes and make sure no infected files are uploaded.",
      },
      {
        icon: HealthBoardIcon,
        title: "Fast and unlimited",
        description: "It scans automatically and whenever you want",
      },
    ],
  },
  3: {
    cards: [
      {
        icon: GuardIcon,
        title: "SSL",
        description:
          "WebTotem won’t let you miss the day of SSL Certificate expiration. Plan certificate prolongation in advance",
      },
      {
        icon: MonitoringIcon,
        title: "Availability",
        description:
          "Make sure that your site is accessible to customers and you haven’t lost your traffic",
      },
      {
        icon: HealthBoardIcon,
        title: "Reputation",
        description:
          "Take care of your presence in search results and take actions if your website is blacklisted",
      },
      {
        icon: GuardIcon,
        title: "Deface scanner",
        description:
          "Make sure that no page of your website has been replaced by a hacker and your website does not mislead your customers",
      },
      {
        icon: MonitoringIcon,
        title: "Server Resources",
        description:
          "Boost your website performance. Control RAM/CPU load data and server disc usage",
      },
    ],
  },
  4: {
    cards: [
      {
        icon: GuardIcon,
        title: "Open Ports Scanner",
        description:
          "Detect open ports on the web server that can be exploited by intruders",
      },
      {
        icon: MonitoringIcon,
        title: "Scoring",
        description:
          "Find out your website security pain points and follow our recommendations to reduce hacker attempts.",
      },
    ],
  },
}

const SolutionSectionSliderTabs = ({ activeTab }) => {
  return (
    <div className="solution-section__slider">
      {cards[activeTab].cards.map(card => (
        <div key={card?.title} className="solution-section__slide">
          <img src={card?.icon} alt={card?.title} />
          <h4 className="solution-section-tab__card__title">{card?.title}</h4>
          <br />
          <p className="solution-section-tab__card__description">
            {card?.description}
          </p>
        </div>
      ))}
    </div>
  )
}

export default SolutionSectionSliderTabs
